.home .home-content h1 {
    font-size: 3rem;
}

.screen {
    max-width: 800px;
}

.container {
    max-width: 85%;
}

.milestone-col{
    border-top:0 !important;
}

.milestone-col+.milestone-col{
    border-top:0 !important;
    border-left: 1px solid rgba(255, 255, 255, .2) !important;
}

@media (max-width:1199.98px) {
    .milestone-col{
        margin-top: .9375rem;
        padding-top: .9375rem;
    }
}

@media (max-width:991.98px) {
    .header .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media(min-width:768px) {
    .container {
        max-width: 90%;
    }

    .screen {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 700px;
    }
}

@media(min-width:991.98px) {
    .screen {
        width: 90%;
    }
}

@media(min-width:1200px) {
    /*.container {*/
    /*    max-width: 90%;*/
    /*}*/
    .screen {
        width: 550px;
    }
}

.brand-text {
    padding-top: 3px;
}

.logo {
    float: left;
    display: block;
    margin-right: 10px;
    position: relative;
    width: 28px;
    height: 28px;
    background: none;
    border: 2px solid hsla(0,0%,100%,.25);
    border-radius: 40px;
}

.logo:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    border: 2px solid hsla(0,0%,100%,.5);
    border-radius: 40px;
}

.logo:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 4px;
    height: 4px;
    background: hsla(0,0%,100%,.75);
    border-radius: 40px;
}


ul > li {
    padding-top: 10px;
    font-size: 20px;
}
